.header {
  width: 100%;
  padding: 30px 0;
  @include media("<=810px") {

  }
  @include media("<=430px") {
  
  }
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 20;
  position: relative;
  @include media("<=810px") {
    flex-direction: column;
    gap: 20px;
    
  }
        @include media("<=600px") {
           
        }
}

.header__nav {
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content:flex-end;
  @include media("<=810px") {
    justify-content: center;
    
    order: 3;
  }
     @include media("<=600px") {
      flex-wrap: wrap;
      width: 91%;
     }
    @include media("<=450px") {
      
    }
}

.header__link {
  position: relative;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  color: #000;
  padding: 16px 32px;
  border-radius: 20px;
    background: #FFF;
    width: fit-content;
  transition: all 0.3s linear;
  @include media("<=550px") {
    order: 2;
    padding: 10px 15px;
    width: fit-content;
  }
  &:hover {
    background: #C1F6E7;

  }
}

.header__link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.08em;
  background: #fdfdfd;
  bottom: -6px;
  left: 0;
  border-radius: 3px;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
}

.header__link:hover::after {
  transform: scale(1, 1);
}

.header__logo {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 22px;
  font-family: "Schibsted Grotesk", sans-serif;
  font-weight: 700;
  @include media("<=810px") {
  
    width: fit-content;
  }
    @include media("<=550px") {
      order: 1;
    }
}
