body {
  margin: 0;
font-family: "Inter", serif;
  color: #fff;
  background-color: #4E3440;
  position: relative;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.container {
  width: 100%;
  max-width: 1100px;
  padding: 0 15px;
  margin: 0 auto;
}

.section__title {
  font-family: "Domine", serif;
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  
  position: relative;

  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
   
   
  }
  @include media("<=430px") {
    font-size: 32px;
  } 
  @include media("<=350px") {
   
  }
  &-black {
    color: #000;
  }
}

.section__subtitle {
  padding: 40px 0;
  text-align: center;
}

.consent {

  background: #fff;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
    position: fixed;
    left: 0%;
    bottom: 0px;
   
    z-index: 21;
    @include media("<=810px") {
   
    }
    
}

.consent__text {
  font-size: 14px;
  color: #131313;
  max-width: 1000px;
  text-align: center;
  span {
    display: block;
    font-size: 30px;
    text-transform: uppercase;
  }
}

.consent__buttons {
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
  width: 100%;
  @include media("<=810px") {
    justify-content: center;
  }
    @include media("<=600px") {
       flex-direction: column;
    }
}

.close {
  color: #1D3DDE;
  font-size: 14px;
  font-weight: 600;
  width: fit-content;
  text-decoration: none;
  padding: 20px;
  text-align: center;
  border-radius: 60px;
    border: 1px solid #1D3DDE;
}

.hide {
  display: none;
}

.page__title {
  font-size: 64px;
  font-weight: 800;
  margin-bottom: 40px;
  text-transform: uppercase;
}

.page__content {
  padding: 40px 0 100px;
}

.page__inner {
  padding-top: 40px;
  width: 100%;
  margin: 0 auto;
  display: flex;
    flex-direction: column;
    row-gap: 18px;
    p {
      padding-bottom: 10px;
    }
    strong {
      padding: 8px 0 10px;
      display: block;
      font-weight: 600;
    }
    ul {
      padding-left: 20px;
      list-style-type: disc;
    }
}

.page__text {
  line-height: 1.2;
  font-size: 15px;
  font-weight: 400;
  p {
    padding-top: 18px;
  }
}


