.info {
    padding: 80px 0;
    @include media("<=810px") {
  
    }
    
}

.info__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    padding-top: 30px;
    @include media("<=810px") {
       flex-wrap: wrap;
       justify-content: center;
       gap: 20px;
    }
}


.info__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 793px;
    font-size: 14px;
    line-height: 1.2;
    @include media("<=810px") {
        gap: 30px;
    }
    span {
        text-transform: uppercase;
        font-size: 30px;
        display: block;
    }
}


.info__text {
    text-align: center;
       font-style: italic;
        font-size: 18px;
        font-weight: 400;
   line-height: 1.3;
            @include media("<=1080px") {
                row-gap: 14px;
                font-size: 14px;
            }
        @include media("<=810px") {
          font-size: 16px;
        }
            
        

}

.info__image {
    max-width: 450px;
    img {
        width: 100%;
        display: block;
    }
}