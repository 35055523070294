.contacts {
    padding: 100px 0;
    background: #BB7171;
    @include media("<=810px") {
        
    }
}

.contacts__subtitle {
    padding: 30px 0 65px;
}

.contacts__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
   gap: 30px;
    @include media("<=810px") {
        flex-direction: column;
        row-gap: 30px;
        width: 100%;
    }
}

.contacts__content {
    max-width: 360px;
    flex: 0 1 100%;
    width: 100%;
}

.form {
   
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;
   
@include media("<=810px") {
  
}
}

.form__input {
    width: 100%;
    input {
        font-family: "Montserrat", sans-serif;
        width: 100%;
        font-size: 16px;
        font-weight: 700;
    color: #fff;
        border: none;
        padding: 10px;
        background: rgba(255, 255, 255, 0.10);
    @include media("<=430px") {
        padding: 20px 15px;
    }
    }
   textarea {
    font-family: "Montserrat", sans-serif;
        width: 100%;
        font-size: 16px;
            font-weight: 700;
            color: #fff;
            border: none;
            padding: 10px;
            background: rgba(255, 255, 255, 0.10);
        resize: none;
   }
}

.form__checkbox {
    color: #fff;
    width: 100%;
    display: flex;
    align-items: flex-start;
    column-gap: 10px;
    font-size: 13px;
    padding: 22px 0;
    a {
        color: #0DD3EE;
            font-size: 14px;
            text-decoration: none;
    }
}

.form__button {
    margin-top: 10px;
    font-family: "Montserrat", sans-serif;
    border-radius: 50px;
    background: #0DD3EE;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #000;
        padding: 16px;
    max-width: 186px;
        border: none;
        font-size: 15px;
        font-weight: 500;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.3s linear;
        &:hover {
            background-color: #fff;
        }
                @include media("<=810px") {
                    max-width: 100%;
                }
}

.form__link {
    color: #000;
    font-size: 13px;
    text-decoration: none;
}

.contacts__text {
    padding: 30px 0;
    font-size: 14px;
    text-align: center;
    line-height: 1.4;
}

.contacts__image {
    max-width: 387px;
    width: 100%;
    img {
        width: 100%;
        display: block;
    }
}