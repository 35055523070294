.about {
  overflow-x: clip;
  position: relative;
  padding: 60px 0;
  @include media("<=1024px") {
  }
  @include media("<=810px") {
    padding-bottom: 80px;
  }
  @include media("<=430px") {
    
  }
  
}

.about__inner {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  gap: 20px;

  @include media("<=810px") {
     flex-direction: column;
     align-items: center;
     row-gap: 30px;
  }
  @include media("<=500px") {
   
  }
}

.about__content {
  max-width: 620px;
  width: 100%;
}

.about__text {
  padding: 40px 0;
  font-size: 18px;
    font-weight: 400;
    line-height: 1.1;
    display: flex;
      flex-direction: column;
      row-gap: 15px;
    @include media("<=430px") {
      font-size: 14px;
    }
}


.about__image {
  max-width: 383px;
  width: 100%;
  img {
    width: 100%;
    display: block;
  }
}

.about__list {
  list-style-type: disc;
  font-size: 18px;
  @include media("<=810px") {
    padding-left: 15px;
  }
  li {
    
  }
}









