.features {
  padding: 80px 0;
background: #BB7171;
@include media("<=810px") {
    padding-bottom: 80px;
}
}

.features__inner {
    padding: 40px 0 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    @include media("<=810px") {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
    }
        @include media("<=430px") {
            
        }
}

.features__subtitle {
    padding-top: 20px;
    font-size: 15px;
}

.features__item {
   
    border-radius: 20px;
        background: #4E3440;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    line-height: 1.2;
    justify-content: center;
    flex: 0 1 48%;
    font-size: 16px;
    text-align: center;
    gap: 30px;
    @include media("<=1080px") {
      
    }
    
        @include media("<=810px") {
            flex: 0 1 100%;
            
        }
        @include media("<=430px") {
            font-size: 16px;
            width: 100%;
        }
        
}

.features__title{
    font-size: 30px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 30px;
    @include media("<=600px") {
        font-size: 24px;
    }
}