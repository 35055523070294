.hero {
  position: relative;
  padding: 109px 0;
  background: #35242C;
  @include media("<=1080px") {

  }

  @include media("<=810px") {
    padding-top: 40px;
  }

  @include media("<=430px") {
    
    
  }
}

.hero__inner {
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  gap: 20px;
  text-align: center;
  @include media("<=1280px") {

    margin: 0 auto;
  }

  @include media("<=1080px") {}

  @include media("<=810px") {
    position: relative;
    flex-direction: column;
    align-items: center;
   row-gap: 30px;
  }

  @include media("<=430px") {}
}

.hero__content {
  max-width: 577px;
  width: 100%;
  display: flex;
    flex-direction: column;
    align-items: center;
}

.hero__title {
  font-size: 40px;
  font-weight: 700;
  font-family: "Domine", serif;
  position: relative;
  z-index: 3;
  text-transform: uppercase;
  margin-bottom: 40px;
  line-height: 1.1;
  @include media("<=1280px") {
   
    
  }

  @include media("<=1080px") {
    font-size: 32px;

  }

  @include media("<=810px") {
   
    
  }
    @include media("<=550px") {
      font-size: 30px;
  
    }

  @include media("<=430px") {
    font-size: 28px;

  }


  @include media("<=350px") {
    font-size: 24px;
  }
}

.hero__subtitle {
  font-size: 18px;
  font-weight: 400;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    @include media("<=430px") {
      gap: 30px;
      font-size: 16px;
    }
}

.hero__text {

  font-size: 14px;

  @include media("<=1280px") {
    padding: 30px;
  }
  @include media("<=430px") {
    padding: 20px;
  }
}

.button {
  font-family: "Montserrat", sans-serif;
    border-radius: 20px;
      background: #C1F6E7;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #000;
    padding: 24px;
    width: 100%;
    max-width: 469px;
    border: none;
    font-size:22px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s linear;
  
    

  @include media("<=810px") {
    margin: 0 auto;
  }
 
  &:hover {
    background: #fff;
  }
  
}

.button-consent {
  max-width: 198px;
  border-radius: 4px;
  background: #0E8EFF;
  color: #fff;
  padding: 10px;
  font-size: 16px;
  &:hover {
    background-color: #094f8c;
  }
}


.hero__image {
  flex: 0 1 100%;
  width: 100%;
  position: relative;
  max-width: 383px;
    overflow: hidden;
  @include media("<=810px") {
   
  }

  @include media("<=430px") {
    
  }

  img {
    width: 100%;
    display: block;
    @include media("<=1280px") {
      
    }
  }
}

