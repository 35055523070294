.footer {
background: #2D2D2D;
  position: relative;
  @include media("<=810px") {
    overflow: visible;
    padding: 30px 0;
  }
  @include media("<=430px") {
    
  }
  
}

.footer__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  
  @include media("<=650px") {
    flex-direction: column;
    gap: 30px;
  }
}

.footer__row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 30px 0;
  gap: 20px;
  &:first-of-type {
    border-bottom: 1px #fff solid;
  }
    @include media("<=650px") {
      flex-direction: column-reverse;
    }
}

.footer__nav {
  gap: 30px;
  display: flex;
  justify-content: flex-end;
  @include media("<=650px") {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.copyright {

  font-size: 14px;
  font-weight: 400;

  @include media("<=810px") {
    font-size: 12px;
  }
}